import { sdwPortalLogger } from './logger';
import { AppShell } from '@allianz/appshell';
import { AngularI18nSupport } from './angular-i18n-support';
import { ILogObj, Logger } from 'tslog';
import { SdwPortalConfig } from './sdw-portal-config';

declare let __webpack_public_path__: string;

function determinePublicPath() {
  const currentScript = document.currentScript as HTMLScriptElement;
  if (currentScript?.src) {
    // take the current script path and remove the filename
    __webpack_public_path__ = currentScript.src.split('/').slice(0, -1).join('/') + '/';
  } else {
    __webpack_public_path__ = '/portal';
    sdwPortalLogger.trace(`currentScript.src not available, using ${__webpack_public_path__} as publicPath`);
  }
  sdwPortalLogger.debug(`setting publicPath to ${__webpack_public_path__}`);
  return __webpack_public_path__;
}

async function createFallbackConfiguration(errorMessage: string): Promise<SdwPortalConfig> {
  const error = new Error(errorMessage);
  sdwPortalLogger.error(error);
  const normalizedPublicPath = new URL(__webpack_public_path__, location.origin).toString();
  const manifestPromise = normalizedPublicPath === `${location.origin}/` ? import('./minimal-fallback.manifest.json') : import(`./portal/minimal-fallback.manifest.json`);
  const minimalFallbackManifest = await manifestPromise;
  return {
    defaultLanguage: 'en',
    appShellManifest: minimalFallbackManifest.default,
    fallback: true
  };
}

function processConfigResponse(response: Response | { statusText: unknown; json: () => Promise<never>; ok: boolean }, configLoaderLogger: Logger<ILogObj>) {
  if (response.ok) {
    configLoaderLogger.trace('receiving config');
    return response
      .json()
      .catch((err) => {
        configLoaderLogger.error('unexpected error while parsing configuration', err);
        return null;
      })
      .then((config) => {
        if (config) {
          sdwPortalLogger.trace('config received', config);
          return config as SdwPortalConfig;
        } else {
          return createFallbackConfiguration('sdw-portal config loaded without proper content');
        }
      });
  } else {
    return createFallbackConfiguration(`sdw-portal config not loaded: ${response.statusText}`);
  }
}

export async function main() {
  const publicPath = determinePublicPath();
  const configLoaderLogger = sdwPortalLogger.getSubLogger({ name: 'ConfigLoader' });
  const appShell = new AppShell('main_wrapper', undefined, sdwPortalLogger);

  configLoaderLogger.trace('fetching config');

  const fetchConfig = fetch(`${publicPath}config/sdw-portal.json`, { headers: { accept: 'application/json' } })
    .catch((err) => {
      return { ok: false, statusText: err.statusText ?? 'fetch failed', json: () => Promise.reject(err) };
    })
    .then((response) => processConfigResponse(response, configLoaderLogger));
  try {
    const config = await fetchConfig;
    configLoaderLogger.trace('config received', config);
    if (config.fallback) {
      sdwPortalLogger.trace(`navigating to error page because of fallback from ${location.href} to /auth/error/500-1000`);
      if (location.pathname !== '/auth/error/500-1000') {
        // prevent infinite loop
        // we can't send a message to the auth
        // as we have no app shell api and also no auth mfe here
        // so using location instead
        location.pathname = '/auth/error/500-1000';
      }
    }

    appShell.configureViews(config.appShellManifest);

    await new AngularI18nSupport(sdwPortalLogger, config.defaultLanguage).setup();

    appShell.init();
  } catch (err) {
    sdwPortalLogger.error(err);
  }
  sdwPortalLogger.info('initialization done');
}
